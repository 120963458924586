import React from 'react';
import { array, object } from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import FeaturesList from '../../components/FeaturesList';
import Seo from '../../components/Seo';

import './style.scss';

export const FeaturesPageTemplate = ({ header, complexContent, content, seo }) => {

  return (
    <>
      <Seo { ...seo } />

      <section className="FeaturesPage">
        <Header { ...header } />

        <FeaturesList complexContent={ complexContent } content={ content }/>
      </section>
    </>
  );
};

FeaturesPageTemplate.propTypes = {
  header: object.isRequired,
  content: array.isRequired,
};

const FeaturesPage = ({ data }) => {
  const { markdownRemark: { frontmatter } } = data;

  return (
    <Layout>
      <FeaturesPageTemplate
        { ...frontmatter }
      />
    </Layout>
  );
};

FeaturesPage.propTypes = {
  data: object.isRequired,
};

export default FeaturesPage;

export const aboutPageQuery = graphql`
  query FeaturesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
          image
        }
        header {
          title
          subtitle
        }
        content {
          title
          icon
          content
          image {
            source
            alt
          }
        }
      }
    }
  }
`;
