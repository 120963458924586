import React from 'react';
import ReactSVG from 'react-svg';

import './style.scss';

const FeatureContentItem = ({ icon, title, content, ctaLabel }) => {
  return (
    <div className="FeatureContentItem">
      <div className="FeatureContentItem__header">
        <ReactSVG src={ icon } className="FeatureContentItem__icon"/>
        <h3 className="FeatureContentItem__title">{ title }</h3>
      </div>

      <div
        className="FeatureContentItem__text"
        dangerouslySetInnerHTML={ { __html: content } }
      />
    </div>
  );
};

export default FeatureContentItem;
