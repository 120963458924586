import React from 'react';
import { string, shape } from 'prop-types';
import cn from 'classnames';

import FeatureContentItem from './FeatureContentItem';

import './style.scss';

const propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
  content: string.isRequired,
  image: shape({
    image: string,
    alt: string,
  }),
  ctaLabel: string.isRequired,
};

const FeaturesList = ({ content }) => {
  return (
    <section className="FeaturesList">
      { content.map((feature, i) => {
        const isImagePresent = feature.image && feature.image.source;

        return (
          <div
            className={
              cn(
                'FeaturesList__item',
                {
                  'FeaturesList__item--reverse': (i % 2 === 0 && isImagePresent) || (i % 2 !== 0 && !isImagePresent),
                  'FeaturesList__item--complex': isImagePresent,
                  'FeaturesList__item--centered': !isImagePresent,
                }
              ) }
            key={ feature.title + i }
          >
            { isImagePresent && (
              <div className="FeaturesList__image">
                <img src={ feature.image.source } alt={ feature.image.alt || feature.title } />
              </div>
            ) }

            <FeatureContentItem { ...feature } />
          </div>
        );
      }) }
    </section>
  );
};

FeaturesList.propTtypes = propTypes;

export default FeaturesList;
